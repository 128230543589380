import { useEffect } from "react";
import { Avatar } from "antd";
import { marked } from "marked";
import hljs from "highlight.js";

import Styles from "./styles/chatRow.module.scss";

const renderer = new marked.Renderer();

const originalCodeRenderer = renderer.code.bind(renderer);
renderer.code = (code, language) => {
  // Placeholder for the custom code head. Ensure this is unique and identifiable.
  const codeHeadPlaceholder = `<div class="code-head-placeholder">${
    language || "Code"
  }</div>
  <pre><code>${code}</code></pre>`;

  return codeHeadPlaceholder + originalCodeRenderer(code, language);
};

marked.setOptions({
  renderer,
  breaks: true,
  gfm: true,
  highlight: function (code, lang) {
    return hljs.highlightAuto(code, [lang]).value;
  },
});

const ChatRow = ({ loading, type, chatMessage }) => {
  useEffect(() => {
    document.querySelectorAll("pre code").forEach((block) => {
      if (!block.dataset.highlighted) {
        hljs.highlightAll();
      }
    });
  }, []);

  const processedContent = marked(chatMessage || "");

  const finalContent = processedContent.replace(
    /<div class="code-head-placeholder">(.+?)<\/div>\s*<pre><code>([\s\S]+?)<\/code><\/pre>/g,
    (match, language, codeContent) => {
      return `<div class="${Styles["code-head"]}">
      ${language}
        <button 
          class="copy-code-button"
          data-code="${codeContent.replace(/"/g, "&quot;")}"
        >
          <svg style="margin-right: 5px" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-sm"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.5C10.8954 3.5 10 4.39543 10 5.5H14C14 4.39543 13.1046 3.5 12 3.5ZM8.53513 3.5C9.22675 2.3044 10.5194 1.5 12 1.5C13.4806 1.5 14.7733 2.3044 15.4649 3.5H17.25C18.9069 3.5 20.25 4.84315 20.25 6.5V18.5C20.25 20.1569 19.1569 21.5 17.25 21.5H6.75C5.09315 21.5 3.75 20.1569 3.75 18.5V6.5C3.75 4.84315 5.09315 3.5 6.75 3.5H8.53513ZM8 5.5H6.75C6.19772 5.5 5.75 5.94772 5.75 6.5V18.5C5.75 19.0523 6.19772 19.5 6.75 19.5H17.25C18.0523 19.5 18.25 19.0523 18.25 18.5V6.5C18.25 5.94772 17.8023 5.5 17.25 5.5H16C16 6.60457 15.1046 7.5 14 7.5H10C8.89543 7.5 8 6.60457 8 5.5Z" fill="currentColor"></path></svg> 
          Copy code
        </button>
      </div>`;
    }
  );

  const copyContent = async (text, button) => {
    try {
      await navigator.clipboard.writeText(text);
      button.innerText = "Copied";
      button.style.pointerEvents = "none";
      setTimeout(() => {
        button.innerHTML = `<svg style="margin-right: 5px" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-sm"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.5C10.8954 3.5 10 4.39543 10 5.5H14C14 4.39543 13.1046 3.5 12 3.5ZM8.53513 3.5C9.22675 2.3044 10.5194 1.5 12 1.5C13.4806 1.5 14.7733 2.3044 15.4649 3.5H17.25C18.9069 3.5 20.25 4.84315 20.25 6.5V18.5C20.25 20.1569 19.1569 21.5 17.25 21.5H6.75C5.09315 21.5 3.75 20.1569 3.75 18.5V6.5C3.75 4.84315 5.09315 3.5 6.75 3.5H8.53513ZM8 5.5H6.75C6.19772 5.5 5.75 5.94772 5.75 6.5V18.5C5.75 19.0523 6.19772 19.5 6.75 19.5H17.25C18.0523 19.5 18.25 19.0523 18.25 18.5V6.5C18.25 5.94772 17.8023 5.5 17.25 5.5H16C16 6.60457 15.1046 7.5 14 7.5H10C8.89543 7.5 8 6.60457 8 5.5Z" fill="currentColor"></path></svg> Copy code`;
        button.style.pointerEvents = "unset";
      }, 1500);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelectorAll(".copy-code-button").forEach((button) => {
        const codeContent = button.getAttribute("data-code");
        button.addEventListener("click", () =>
          copyContent(codeContent, button)
        );
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={Styles["chat-row"]}>
      <div className={Styles["chat-column"]}>
        <div>
          <Avatar
            style={{ background: type === "user" ? "red" : "#ab68ff" }}
            className={Styles.avatar}
          >
            {type === "user" ? "L" : "G"}
          </Avatar>
        </div>
        <div className={Styles["chat-message"]}>
          <div className={Styles.sender}>
            {type === "user" ? "You" : "Chat"}
          </div>
          {loading ? (
            <div className={Styles.message}>
              <p>Loading...</p>
            </div>
          ) : (
            <>
              <div
                className={Styles.message}
                dangerouslySetInnerHTML={{ __html: finalContent }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatRow;
