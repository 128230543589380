export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const generateUserChatMessage = (message, image) => {
  return image
    ? `<div class="chat-image"><img src=${image} alt="" /></div> ${message}`
    : message;
};

export const storage = (type, key, value) => {
  return type === "get"
    ? localStorage.getItem(key)
    : type === "set"
    ? localStorage.setItem(key, value)
    : type === "delete"
    ? localStorage.removeItem(key)
    : null;
};

export const extractLanguage = (text) => {
  const regex = /```(\w+)/;
  const match = text.match(regex);
  return match ? match[1] : null;
};
