import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, message } from "antd";

import { useAppContext } from "../../../context/appContext";
import { Button, Input } from "../../../components/form";
import { login, resetPassword, signUp } from "../../../utils/apiUtils";

import Styles from "./styles/password.module.scss";

const Password = ({ email, isSignUp, isResetPassword }) => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setMeDetails } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnFinish = async (data) => {
    setIsLoading(true);
    const finalData = {
      email: data.email,
      password: data.password,
    };
    if (isSignUp) {
      const response = await signUp(finalData);
      if (response.status === 201) {
        message.open({
          type: "success",
          content: response.data.message,
        });
        navigate("/login");
      }
    } else if (isResetPassword) {
      const response = await resetPassword({ email: data.email });
      if (response.status === 200) {
        message.open({
          type: "success",
          content: response.data.message,
        });
        navigate("/login");
      }
    } else {
      const response = await login(finalData);
      if (response.status === 200 && response.data?.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
        if (!response.data.is_verified) {
          setIsAuthenticated(false);
          navigate("/verify");
        } else {
          setMeDetails(response.data);
          setIsAuthenticated(true);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <div className={Styles.password}>
      {isSignUp ? (
        <h1>Create your account</h1>
      ) : isResetPassword ? (
        <h1>Reset your password</h1>
      ) : (
        <h1>Enter your password</h1>
      )}
      <Form onFinish={handleOnFinish} initialValues={{ email: email }}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please input valid email!" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        {!isResetPassword && (
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
        )}

        {/* {isResetPassword && (
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm password" />
          </Form.Item>
        )} */}

        {!isSignUp && !isResetPassword && (
          <Form.Item className={Styles.forgot}>
            <Link to={"/forgot"}>Forgot password</Link>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Password;
