import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { generateUserChatMessage, getBase64 } from "../../utils/helper";
import {
  getChatMessage,
  createChat,
  upload,
  getAllChat,
  getChatById,
  getDefaultMessage,
} from "../../utils/apiUtils";

import { useAppContext } from "../../context/appContext";
// import { ApiKeyModal } from "../../components/modals";
// import StripModal from "../../components/modals/stripModal";
import ChatInput from "../../components/chatInput";
import ChatRow from "../../components/chatRow";
import Header from "../../components/header";

import Phone from "../../assets/images/phone.png";

import Styles from "./styles/home.module.scss";

const timer = 1500;

const Home = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setMenuItems } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [chat, setChat] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [defaultMessgae, setDefaultMessage] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(true);
  // const [isKeyModalOpen, setIsKeyModalOpen] = useState(true);

  // useEffect(() => {
  //   getAllChatNav();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getAllChatNav = useCallback(async () => {
    const response = await getAllChat();
    if (response.status === 200) {
      const tempArray = [];
      response.data.chats.forEach((item) => {
        tempArray.push({ label: item.label, key: item.id });
      });
      setMenuItems(tempArray.sort((a, b) => b.key - a.key));
    }
  }, [setMenuItems]);

  useEffect(() => {
    (async () => {
      if (id) {
        setDefaultMessage(null);
        const getChatByIdResponse = await getChatById(id);
        if (getChatByIdResponse.status === 200) {
          const tempArray = [];
          getChatByIdResponse.data.messages.forEach((item) => {
            tempArray.push({
              type: item.msg_type,
              message: item.message,
            });
          });
          setChat(tempArray);
        }
      } else {
        const defaultMessgae = await getDefaultMessage();
        setDefaultMessage(defaultMessgae.data.message);
        setChat([]);
      }
    })();
  }, [id, setMenuItems]);

  const handleNavClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInput = async (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (!id) {
        setMenuItems((prev) => [{ label: "New Chat", key: "item1" }, ...prev]);
      }
      const getMessage = await generateUserChatMessage(
        e.target.value,
        imagePreview
      );
      setChat((prev) => [
        ...prev,
        {
          type: "user",
          message: getMessage,
          isImage: !!imagePreview,
          image: imagePreview,
        },
      ]);
      setLoading(true);
      setInputValue("");
      setImagePreview("");
    }
  };

  useEffect(() => {
    if (chat.length) {
      setDefaultMessage(null);
    }
  }, [chat]);

  useEffect(() => {
    scrollToBottom();
    if (chat.length) {
      (async () => {
        const lastChatItem = chat[chat.length - 1];
        const isUserMessage = lastChatItem.type === "user";
        const userMessage =
          (lastChatItem.type === "user" && lastChatItem.message) || "";
        const isImage =
          (lastChatItem.type === "user" && lastChatItem.isImage) || false;

        const createOrUploadMessage = async (id) => {
          const response = isImage
            ? await upload({
                file_data: lastChatItem.image,
              })
            : await getChatMessage(
                {
                  message: userMessage,
                },
                id
              );

          if (response.status === 201) {
            if (response?.data?.data?.interactions.length > 1) {
              response?.data?.data?.interactions.forEach((item, index) => {
                setTimeout(() => {
                  setChat((prev) => [
                    ...prev,
                    {
                      type: "system",
                      message: item.value,
                      index: item.index,
                    },
                  ]);
                }, timer * index);
              });
            } else {
              setChat((prev) => [
                ...prev,
                {
                  type: "system",
                  message: response?.data?.interactions[0].value,
                  index: response?.data?.interactions[0].index || null,
                },
              ]);
              getAllChatNav();
            }
            navigate(`/chat/${id}`);
          }
          setLoading(false);
        };

        if (isUserMessage) {
          if (!id) {
            const chatResponse = await createChat();

            if (chatResponse.status === 201) {
              createOrUploadMessage(chatResponse.data.id);
            }
          } else {
            createOrUploadMessage(id);
          }
        }
      })();
    }
  }, [chat, id, navigate, getAllChatNav]);

  const handleOnUpload = async (files) => {
    const preview = await getBase64(files?.file?.originFileObj);
    setImagePreview(preview);
  };

  const handleOnUploadDelete = () => {
    setImagePreview("");
  };

  const scrollToBottom = () => {
    const elem = document.getElementById("chat-area");
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  };

  return (
    <div className={Styles["chat-wrapper"]}>
      <Header handleNavClick={handleNavClick} />
      <div className={`${Styles["chat-area"]} ${isOpen ? Styles.open : ""}`}>
        <div className={Styles["chat-scroll"]} id="chat-area">
          {defaultMessgae && (
            <div className={Styles["default-message"]}>
              <div className={Styles["message"]}>
                <p>{defaultMessgae}</p>
              </div>
            </div>
          )}
          {chat.map((item, itemIndex) => (
            <ChatRow
              key={itemIndex}
              type={item.type}
              chatMessage={item.message}
            />
          ))}
          {loading && <ChatRow loading type="system" />}
        </div>

        <div className={Styles["chat-input"]}>
          <ChatInput
            value={inputValue}
            imagePreview={imagePreview}
            onChange={(e) => setInputValue(e.target.value)}
            onUpload={handleOnUpload}
            onUploadDelete={handleOnUploadDelete}
            onPressEnter={handleInput}
          />
        </div>
      </div>
      <div className={`${Styles.preview} ${isOpen ? Styles.open : ""}`}>
        <div className={Styles["preview-area"]}>
          <h4>Web Preview</h4>
          <img src={Phone} alt="" />
        </div>
      </div>
      {/* <ApiKeyModal
        title="New API Key"
        open={isKeyModalOpen}
        centered
        onCancel={() => setIsKeyModalOpen(false)}
      /> */}
      {/* <StripModal
        title="Payment"
        open={isModalOpen}
        centered
        destroy
        onCancel={() => setIsModalOpen(false)}
      /> */}
    </div>
  );
};

export default Home;
