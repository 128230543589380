import { List, Select } from "antd";

import Heading from "./heading";

const General = ({ heading }) => {
  return (
    <>
      <Heading heading={heading} />
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={[
          {
            heading: "Theme",
            description: "Testing",
            action: [
              <Select
                options={[
                  {
                    label: "Test",
                    value: "Test",
                  },
                ]}
              />,
            ],
          },
        ]}
        renderItem={(item) => (
          <List.Item actions={item.action}>
            <List.Item.Meta
              title={item.heading}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default General;
