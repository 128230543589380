import { useEffect, useState } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../../context/appContext";
import { updateUser } from "../../../utils/apiUtils";
import { Button, Input } from "../../../components/form";
import { storage } from "../../../utils/helper";

import Styles from "./styles/verify.module.scss";

const Verify = () => {
  const navigate = useNavigate();
  const { setMeDetails, setIsAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnFinish = async (data) => {
    setIsLoading(true);
    const finalData = {
      name: data.name,
    };
    const response = await updateUser(finalData);
    if (response.status === 200) {
      setMeDetails(response.data);
      setIsAuthenticated(true);
      navigate("/");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const token = storage("get", "access_token");
    if (!token) {
      navigate("/");
    }
  });

  return (
    <>
      <h1 className={Styles["signup-headong"]}>Tell us about you</h1>
      <Form onFinish={handleOnFinish}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Verify;
