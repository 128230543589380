import { Button, Divider, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";

import Heading from "./heading";

import FormStyles from "./styles/form.module.scss";

const ApiKeys = ({ heading }) => {
  return (
    <>
      <Heading heading={heading} />
      <Form
        layout="vertical"
        className={FormStyles.form}
        initialValues={{ api_keys: [""] }}
      >
        <Form.List name="api_keys">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className={FormStyles["delete-button"]}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                  <Form.Item name="api_key_name" label="API Key Name">
                    <Input placeholder="Enter Key Name" />
                  </Form.Item>
                  <Form.Item name="api_key" label="API Key">
                    <Input placeholder="Enter Key" />
                  </Form.Item>
                  {index < fields.length - 1 && <Divider />}
                </>
              ))}
              <Form.Item>
                <Button
                  type="link"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  className={FormStyles["add-btn"]}
                >
                  Add More Keys
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Button type="primary" icon={<PlusOutlined />}>Save</Button>
      </Form>
    </>
  );
};

export default ApiKeys;
