import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { KeyOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";

import General from "./general";
import ApiKeys from "./apiKeys";

import Styles from "./styles/settings.module.scss";

const Settings = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const pageName = hash.split("/")[1];

  const handleSettingRendering = (key) => {
    switch (key) {
      case "General":
        return <General heading="General" />;
      case "ApiKeys":
        return  <ApiKeys heading="API Keys" />;
      case "Profile":
        return <p>Profile</p>;
      default:
        return <General heading="General" />;
    }
  };

  const activePageName = (key) => {
    switch (key) {
      case "General":
        return "General";
      case "ApiKeys":
        return "ApiKeys";
      case "Profile":
        return "Profile";
      default:
        return "General";
    }
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.menu}>
        <h2>Settings</h2>
        <Menu
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          className={Styles["menus"]}
          activeKey={activePageName(pageName)}
          items={[
            {
              key: "General",
              icon: <SettingOutlined />,
              onClick: () => navigate("#settings/General"),
              label: "General",
            },
            {
              key: "ApiKeys",
              icon: <KeyOutlined />,
              onClick: () => navigate("#settings/ApiKeys"),
              label: "API Keys",
            },
            {
              key: "Profile",
              icon: <UserOutlined />,
              onClick: () => navigate("#settings/Profile"),
              label: "Profile",
            },
          ]}
        />
      </div>
      <div className={Styles.content}>{handleSettingRendering(pageName)}</div>
    </div>
  );
};

export default Settings;
