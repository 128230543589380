import { Button, Input, Tooltip, Upload } from "antd";
import { CloseCircleFilled, UploadOutlined } from "@ant-design/icons";

import Styles from "./styles/chatInput.module.scss";

const ChatInput = ({ onUpload, imagePreview, onUploadDelete, ...props }) => {
  return (
    <div className={Styles["chat-input"]}>
      <div className={Styles["input-wrapper"]}>
        {imagePreview && (
          <div className={Styles["image-wrapper"]}>
            <div className={Styles.image}>
              <Tooltip title="Remove File">
                <CloseCircleFilled
                  className={Styles.close}
                  onClick={onUploadDelete}
                />
              </Tooltip>
              <img src={imagePreview} alt="" />
            </div>
          </div>
        )}
        <div className={Styles.inner}>
          <Upload
            className={Styles.upload}
            fileList={[]}
            onChange={onUpload}
            multiple={false}
            accept={[".jpg", ".png"]}
          >
            <Button type="link">
              <UploadOutlined style={{ fontSize: 20, color: "#fff" }} />
            </Button>
          </Upload>
          <Input.TextArea
            placeholder="Message LivRobo"
            autoSize={{ maxRows: 7 }}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
