import { useEffect, useState } from "react";
import { ConfigProvider, Spin } from "antd";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import "./utils/interseptor";
import { storage } from "./utils/helper";
import { getUser } from "./utils/apiUtils";
import { AuthLayout, Layout } from "./components/layouts";
import AppContext from "./context/appContext";

import Home from "./pages/home";
import Login from "./pages/user/login";
import Verify from "./pages/user/verify";
import ResetPassword from "./pages/user/login/resetPassword";

import "highlight.js/styles/github-dark.css";

function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [meDetails, setMeDetails] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const token = storage("get", "access_token");
      if (token) {
        const response = await getUser();
        if (response.status === 200) {
          setMeDetails(response.data);
          if (!response.data.is_verified) {
            setIsAuthenticated(false);
            navigate("/verify");
          } else {
            setIsAuthenticated(true);
          }
        }
      } else {
        setIsAuthenticated(false);
      }
    })();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        meDetails,
        setMeDetails,
        menuItems,
        setMenuItems,
        isSettingModalOpen,
        setIsSettingModalOpen,
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#10a37f",
            fontFamily: '"Roboto", sans-serif',
          },
        }}
      >
        {isAuthenticated === null ? (
          <Spin />
        ) : (
          <>
            {isAuthenticated ? (
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/chat/:id" element={<Home />} />
                  <Route path="*" element={<Navigate to={"/"} />} />
                </Routes>
              </Layout>
            ) : (
              <AuthLayout>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  {/* <Route path="/sign-up" element={<Login isSignUp />} /> */}
                  <Route path="/forgot" element={<Login isResetPassword />} />
                  <Route
                    path="/reset-password/:token"
                    element={<ResetPassword />}
                  />
                  <Route path="/verify" element={<Verify />} />
                  <Route path="*" element={<Navigate to={"/login"} />} />
                </Routes>
              </AuthLayout>
            )}
          </>
        )}
      </ConfigProvider>
    </AppContext.Provider>
  );
}

export default App;
