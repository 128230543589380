import { useNavigate, useParams } from "react-router-dom";
import { Form, message } from "antd";

import { Button, Input } from "../../../components/form";
import { forgotPassword } from "../../../utils/apiUtils";

import Styles from "./styles/resetPassword.module.scss";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const handleOnFinish = async (data) => {
    const response = await forgotPassword(
      { new_password: data.confirm },
      token
    );
    if (response.status === 200) {
      message.open({
        type: "success",
        content: response.data.message,
      });
      navigate("/login");
    } else {
      navigate("/forgot");
    }
  };

  return (
    <div className={Styles["reset-password"]}>
      <h1>Change password</h1>
      <Form onFinish={handleOnFinish}>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm password" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
