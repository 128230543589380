import axios from "axios";

axios.interceptors.request.use((request) => {
  const authorization = localStorage.getItem("access_token");
  if (authorization) {
    request.headers["Authorization"] = `Bearer ${authorization}`;
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("access_token");
      window.location = "/";
      return Promise.reject(error);
    }
    throw error;
  }
);
