import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "antd";

import Email from "./email";
import Password from "./password";

import Styles from "./styles/login.module.scss";

const Login = ({ isSignUp, isResetPassword }) => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState(null);
  const steps = [
    // <Email
    //   step={step}
    //   setStep={setStep}
    //   setEmail={setEmail}
    //   isSignUp={isSignUp}
    //   isResetPassword={isResetPassword}
    //   email={email}
    // />,
    <Password
      email={email}
      isSignUp={isSignUp}
      isResetPassword={isResetPassword}
    />,
  ];

  useEffect(() => {
    setStep(0);
  }, [isSignUp, isResetPassword]);

  return (
    <>
      {steps[step]}
      <div className={Styles["have-account"]}>
        {isSignUp ? (
          <>
            Already have an account? <Link to={"/login"}>Log in</Link>
          </>
        ) : (
          <>
            {isResetPassword && (
              <Link to={"/login"}>Back to login</Link>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Login;
