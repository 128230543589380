import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Modal } from "antd";

import { useAppContext } from "../../context/appContext";
import { getAllChat } from "../../utils/apiUtils";

import Settings from "../../pages/settings";
import UserAvatar from "./userAvatar";

import Styles from "./styles/leftNav.module.scss";

const LeftNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hash } = useLocation();
  const { menuItems, setMenuItems, isSettingModalOpen, setIsSettingModalOpen } =
    useAppContext();

  const handleMenuClick = (e) => {
    navigate(`/chat/${e.key}`);
  };

  useEffect(() => {
    getAllChatNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasSettings = hash.includes("#settings");
    if (hasSettings) {
      setIsSettingModalOpen(true);
    }
  }, [hash, setIsSettingModalOpen])

  const getAllChatNav = useCallback(async () => {
    const response = await getAllChat();
    if (response.status === 200) {
      const tempArray = [];
      response.data.chats.forEach((item) => {
        tempArray.push({ label: item.label, key: item.id });
      });
      setMenuItems(tempArray.sort((a, b) => b.key - a.key));
    }
  }, [setMenuItems]);

  return (
    <div className={Styles["left-nav"]}>
      <UserAvatar />
      <div className={Styles["scroll-area"]}>
        <Menu
          onClick={handleMenuClick}
          mode="inline"
          className={Styles.menu}
          items={[...menuItems]}
        />
      </div>
      <Modal
        width={650}
        open={isSettingModalOpen}
        centered
        onCancel={() => {
          setIsSettingModalOpen(false);
          navigate(location.pathname);
        }}
        className={Styles["settings-modal"]}
        maskClosable={false}
        footer={[]}
      >
        <Settings />
      </Modal>
    </div>
  );
};

export default LeftNav;
