import axios from "axios";
import { message } from "antd";

const ApiHost = process.env.REACT_APP_API_HOST;

export const getDefaultMessage = () => {
  return axios
    .get(`/api/chat/default`)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const getAllChat = (data) => {
  return axios
    .get(`/api/chat`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const getChatById = (id) => {
  return axios
    .get(`/api/chat/${id}/messages`)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const createChat = (data) => {
  return axios
    .post(`/api/chat`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const getChatMessage = (data, id) => {
  return axios
    .post(`/api/chat/${id}/messages`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const upload = (data) => {
  const sessionId = localStorage.getItem("Session-Id") || null;
  return axios
    .post(`${ApiHost}/upload-image`, data, {
      headers: {
        "session-id": sessionId,
      },
    })
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const login = (data) => {
  return axios
    .post(`/api/auth/login`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const signUp = (data) => {
  return axios
    .post(`/api/auth/signup`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const resetPassword = (data) => {
  return axios
    .post(`/api/auth/reset-password`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const forgotPassword = (data, token) => {
  return axios
    .post(`/api/auth/reset_password/${token}`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const getUser = () => {
  return axios
    .get(`/api/user/`)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const updateUser = (data) => {
  return axios
    .put(`/api/user/`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};

export const checkEmail = (data) => {
  return axios
    .post(`/api/auth/check-email`, data)
    .then((resp) => resp)
    .catch((err) => {
      message.open({
        type: "error",
        content:
          err?.response?.data?.message ||
          "Something went wrong, please try again in sometime.",
      });
      return err.response;
    });
};
