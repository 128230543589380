import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Menu,
  Modal,
  Form,
  Input,
  Statistic,
  Card,
  Row,
  Col,
  Tag,
} from "antd";
import {
  ExportOutlined,
  FormOutlined,
  InfoCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import Styles from "./styles/header.module.scss";

const Header = ({ handleNavClick }) => {
  const navigate = useNavigate();
  const [isDownload, setIsDownload] = useState(false);
  const [isWebPublish, setIsWebPublish] = useState(false);
  const [isPublishAndroid, setIsPublishAndroid] = useState(false);
  const [isDisableInput, setIsDisableInput] = useState(false);

  const handleClick = (e) => {
    if (e.key === "preview") {
      handleNavClick();
    } else if (e.key === "download") {
      setIsDownload(true);
    } else if (e.key === "publish-web-app") {
      setIsWebPublish(true);
    } else if (e.key === "publish-android") {
      setIsPublishAndroid(true);
    }
  };

  return (
    <>
      <div className={Styles.head}>
        <Button className={Styles["hamburger"]}>
          <MenuOutlined style={{ color: "#ececf1", fontSize: 18 }} />
        </Button>
        <Button
          className={`${Styles["hamburger"]} ${Styles["new-chat"]}`}
          onClick={() => navigate("/")}
        >
          <FormOutlined style={{ color: "#ececf1", fontSize: 18 }} />
        </Button>
        <Menu
          mode="horizontal"
          className={Styles["nav"]}
          onClick={handleClick}
          triggerSubMenuAction="click"
          items={[
            {
              label: "Web Preview",
              key: "preview",
            },
            {
              label: "Mobile",
              key: "mobile",
            },
            {
              label: "Download",
              key: "download",
            },
            {
              label: "Share",
              key: "share",
            },
            {
              label: "Publish",
              key: "publish",
              children: [
                {
                  label: "Publish Web app",
                  key: "publish-web-app",
                },
                {
                  label: "Publish Android",
                  key: "publish-android",
                },
              ],
            },
          ]}
        />
      </div>

      <Modal
        open={isDownload || isPublishAndroid}
        title={
          isPublishAndroid ? "Publish App: Android" : "Download App: Android"
        }
        centered
        destroyOnClose
        width={700}
        onCancel={() => {
          setIsDownload(false);
          setIsPublishAndroid(false);
        }}
      >
        <Form
          layout="vertical"
          className={Styles["download-form"]}
          requiredMark={false}
        >
          <label>Email</label>
          <p>
            Enter an email address where we'll send you a link to download your
            app
          </p>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>

          <label>Android Build Format</label>
          {isPublishAndroid ? (
            <p>
              Please note that you are downloading an AAB file for Play Store
              Distribution.
            </p>
          ) : (
            <p>
              Please note that you are downloading an APK file for Local Testing
              or Manual Distribution.
            </p>
          )}

          <Form.Item>
            <Card className={Styles.card}>
              <Statistic
                title="App downloads this month"
                value={isPublishAndroid ? "1 / unlimited" : "1 / 2"}
                // precision={2}
                // valueStyle={{ color: "#3f8600" }}
              />
            </Card>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={isWebPublish}
        title="Publish as a Web App"
        centered
        destroyOnClose
        width={700}
        className={Styles["publish-modal"]}
        onCancel={() => setIsWebPublish(false)}
        footer={[
          <Button
            type="link"
            className={Styles["disable-btn"]}
            onClick={() => setIsDisableInput(!isDisableInput)}
          >
            {isDisableInput ? "Enable" : "Disable"} published link
            <InfoCircleOutlined />
          </Button>,
          <Button type="primary" onClick={() => setIsWebPublish(false)}>
            Done
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          className={Styles["download-form"]}
          requiredMark={false}
        >
          <label>Web app URL</label>
          <Row align="top" gutter={15}>
            <Col span={20}>
              <Input
                placeholder="App URL"
                prefix={<Tag color="#87d068">Published</Tag>}
                suffix={<ExportOutlined />}
                defaultValue="https://livrobo.com/w/FosuFZXi"
                disabled={isDisableInput}
              />
              <p className={Styles.published}>
                Latest version published a few seconds ago
              </p>
            </Col>
            <Col span={4}>
              <Button disabled={isDisableInput}>Copy Link</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Header;
