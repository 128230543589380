import LeftNav from "../../leftNav";

import Styles from "./styles/layout.module.scss";

const Layout = ({ children }) => {
  return (
    <div className={Styles.layout}>
      <LeftNav />
      <div className={Styles.content}>{children}</div>
    </div>
  );
};

export default Layout;
