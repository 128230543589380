import { useNavigate } from "react-router-dom";
import { Avatar, Dropdown } from "antd";
import {
  LogoutOutlined,
  SettingOutlined,
  StarOutlined,
} from "@ant-design/icons";

import { storage } from "../../utils/helper";
import { useAppContext } from "../../context/appContext";

import Styles from "./styles/userAvatar.module.scss";

const UserAvatar = () => {
  const navigate = useNavigate();
  const {
    meDetails,
    setIsAuthenticated,
    setMeDetails,
    setMenuItems,
  } = useAppContext();

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: "My Profile",
            key: "1",
            icon: <StarOutlined />,
          },
          {
            label: "Settings",
            key: "2",
            // onClick: () => setIsSettingModalOpen(true),
            icon: <SettingOutlined />,
          },
          {
            type: "divider",
          },
          {
            label: "Logout",
            key: "3",
            icon: <LogoutOutlined />,
            onClick: () => {
              setIsAuthenticated(false);
              storage("delete", "access_token");
              setMeDetails(null);
              setMenuItems([]);
              navigate("/login");
            },
          },
        ],
      }}
      trigger={["click"]}
    >
      <div className={Styles.avatar}>
        <Avatar style={{ background: "red" }}>
          {meDetails?.name.charAt(0).toUpperCase()}
        </Avatar>
        {meDetails?.name}
      </div>
    </Dropdown>
  );
};

export default UserAvatar;
