import Logo from "../../../assets/images/logo.png";

import Styles from "./styles/authLayout.module.scss";

const AuthLayout = ({ children }) => {
  return (
    <div className={Styles["auth-layout"]}>
      <div className={Styles["container"]}>
        <div className={Styles.logo}>
          <img src={Logo} alt="LivRobo" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
