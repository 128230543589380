import { Input as AntdInput } from "antd";

import PasswordInput from "./password";

import Styles from "./styles/input.module.scss";

const Input = ({ ...props }) => {
  return <AntdInput className={Styles.input} {...props} />;
};

Input.Password = PasswordInput;

export default Input;
